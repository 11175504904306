<template>
	<div id="booking">
		<v-head :title="title"></v-head>
		<router-view></router-view>
	</div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead,
		},
		data: function(){
			return {
				title: null
			}
		},
		mounted: function(){
			var path = this.$route.path
			if(path == '/doctor/booking') {
				this.title = '預約掛號'
			} else if(/^\/doctor\/list/.test(path)) {
				this.title = '香港中醫師'
			}
		},
		watch: {
			$route: function(to, from) {
				var path = this.$route.path
				if(path == '/doctor/booking') {
					this.title = '預約掛號'
				} else if(/^\/doctor\/list/.test(path)) {
					this.title = '香港中醫師'
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
   #booking {
	   width: 100%;
	   padding-top: 83px;
   }
</style>
